<template>
  <div>
    <div>
      <!-- 搜索框 -->
      <a-card style="margin-bottom: 24px">
        <a-row :gutter="0" style="line-height: 3">
          <div class="table-operator" style="margin-bottom: -8px">
            <a-form layout="inline">
              <a-row :gutter="5" style="line-height: 3.5">
                <!-- 合同编号 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="模板名称:">
                    <a-input v-model="queryParam.name" placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <!-- 模板类型 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="模板类型:">
                    <a-select
                      allowClear
                      style="width: 175px"
                      v-model="queryParam.contractType"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in templateTypeList"
                        :key="item.code"
                        >{{ item.optionText }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 归属城市 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="归属城市:">
                    <a-select
                      allowClear
                      style="width: 175px"
                      v-model="queryParam.cityId"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in allCities"
                        :key="item.id"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 证照类型 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="证照类型:">
                    <a-select
                      allowClear
                      style="width: 175px"
                      v-model="queryParam.licenseType"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in licenseTypeList"
                        :key="item.id"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 合同版本 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="合同版本:">
                    <a-select
                      allowClear
                      style="width: 175px"
                      v-model="queryParam.contractVersionType"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in contractVersionType"
                        :key="item.code"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="12">
                  <a-form-item>
                    <a-button type="primary" @click="showAddModal"
                      >新建模版</a-button
                    >
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="12" class="fr">
                  <a-form-item>
                    <a-button type="primary" @click="initTable()"
                      >搜索</a-button
                    >
                  </a-form-item>
                  <a-form-item>
                    <a-button @click="resetQueryParam">重置</a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-row>
      </a-card>
      <!-- table -->
      <a-spin :spinning="dataLoading">
        <a-card style="margin-bottom: 24px">
          <a-row :gutter="0" style="line-height: 3">
            <a-table
              rowKey="id"
              :pagination="pagination"
              :columns="columns"
              :dataSource="tableData"
              :scroll="{ x: 800 }"
              @change="changePageSize"
            >
              <span slot="startDateLg" slot-scope="text, record">
                {{ record.startDateLg }} — {{ record.endDateLg }}
              </span>
              <span slot="action" slot-scope="text, record" class="blueColor">
                <a @click="viewDetail(record)" style="margin-right: 0.8em"
                  >编辑</a
                >
                <a @click="handleViewModal(record)" style="margin-right: 0.8em"
                  >查看模版</a
                >
              </span>
            </a-table>
          </a-row>
        </a-card>
      </a-spin>
      <a-modal
        title="查看模版"
        width="1000px"
        v-model="moadlVisible"
        @cancel="cancelVisible"
        footer
      >
        <div class="fr">
          <a-upload
            accept=".doc,.docx,.dot,.dotx,.docm,.dotm"
            withCredentials
            :customRequest="uploadWord"
            style="margin: 24px"
            :showUploadList="false"
          >
            <a-button style="margin-left: 8px" type="primary" :loading="dataLoading"
              >上传新模版</a-button
            >
          </a-upload>
        </div>
        <a-table
          rowKey="id"
          :pagination="templatePagination"
          :columns="detailColumns"
          :dataSource="templateData"
          @change="changePageTemplateSize"
        >
          <span slot="time" slot-scope="text, record">
            {{ moment(record.gmtCreated).format("YYYY-MM-DD HH:mm:ss") }}
          </span>
          <span slot="enabled" slot-scope="text, record">
            {{ record.enabled == "N" ? "未启用" : "启用中" }}
          </span>
          <span slot="content" slot-scope="text, record" class="blueColor">
            <a
              target="_blank"
              :href="record.url"
              :download="record.url"
              style="margin-right: 0.8em"
              @click="viewTemplate(record)"
              >查看</a
            >
            <a
              target="_blank"
              :href="record.word"
              :download="record.word"
              style="margin-right: 0.8em"
              @click="downloadTemplate(record)"
              >下载</a
            >
            <a
              v-if="record.enabled == 'N'"
              @click="activeTemplate(record)"
              style="margin-right: 0.8em"
              >启用</a
            >
          </span>
        </a-table>
      </a-modal>
      <!-- 新建 -->
      <a-modal
        title="新建模版"
        :width="400"
        :visible="addNewVisible"
        :confirmLoading="dataLoading"
        destroyOnClose
        @ok="addNewModal"
        @cancel="cancelAddNewModal"
      >
        <a-spin :spinning="dataLoading">
          <a-form :form="newModalForm">
            <a-form-item label="模板名称">
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入' }] },
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="模板类型">
              <a-select
                v-decorator="[
                  'contractType',
                  { rules: [{ required: true, message: '请选择' }] },
                ]"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="item in templateTypeList"
                  :key="item.code"
                  >{{ item.optionText }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="归属城市">
              <a-select
                v-decorator="[
                  'cityId',
                  { rules: [{ required: true, message: '请选择' }] },
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in allCities" :key="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="证照类型">
              <a-select
                v-decorator="[
                  'licenseType',
                  { rules: [{ required: true, message: '请选择' }] },
                ]"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="item in licenseTypeList"
                  :key="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="合同版本">
              <a-select
                v-decorator="[
                  'contractVersionType',
                  { rules: [{ required: true, message: '请选择' }] },
                ]"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="item in contractVersionType"
                  :key="item.code"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
      <!-- 编辑 -->
      <a-modal
        title="修改模版"
        :width="400"
        :visible="editVisible"
        :confirmLoading="dataLoading"
        destroyOnClose
        @ok="changeEditModal"
        @cancel="cancelEditModal"
      >
        <a-spin :spinning="dataLoading">
          <a-form :form="editModalForm">
            <a-form-item label="模板名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入',
                      },
                    ],
                    initialValue: detail.name,
                  },
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="模板类型">
              <a-select
                v-decorator="[
                  'contractType',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择',
                      },
                    ],
                    initialValue: detail.contractType,
                  },
                ]"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="item in templateTypeList"
                  :key="item.code"
                  >{{ item.optionText }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="归属城市">
              <a-select
                v-decorator="[
                  'cityId',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择',
                      },
                    ],
                    initialValue: detail.cityId,
                  },
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in allCities" :key="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="证照类型">
              <a-select
                v-decorator="[
                  'licenseType',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择',
                      },
                    ],
                    initialValue: detail.licenseType,
                  },
                ]"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="item in licenseTypeList"
                  :key="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="合同版本">
              <a-select
                v-decorator="[
                  'contractVersionType',
                  {
                    rules: [{ required: true, message: '请选择' }],
                    initialValue: detail.contractVersionType,
                  },
                ]"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="item in contractVersionType"
                  :key="item.code"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </div>
  </div>
</template>
<script>
  
import axios from "axios";
import moment from "moment";
import { defaultPagination } from "../../../utils/pagination";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";
import { hasPermissions } from "../../../utils/auth";
import { OrganizationActions } from "../../../utils/actions";
export default {
  name: "contractTemplates",
  data() {
    return {
      //提交表格
      newModalForm: this.$form.createForm(this),
      // 搜索
      queryParam: {},
      // 分页
      pagination: defaultPagination(() => {}),
      pageSize: 20,
      current: 1,
      // 表头
      columns: [
        {
          title: "模板名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "模板类型",
          dataIndex: "contractTypeName",
          key: "contractTypeName",
        },
        {
          title: "归属城市",
          dataIndex: "cityName",
          key: "cityName",
        },
        {
          title: "证照类型",
          dataIndex: "licenseTypeName",
          key: "licenseTypeName",
        },
        {
          title: "合同版本",
          dataIndex: "contractVersionTypeName",
          key: "contractVersionTypeName",
        },
        {
          title: "操作",
          key: "action",
          width: 150,
          scopedSlots: { customRender: "action" },
        },
      ],
      // 数据
      tableData: [],
      // dataLoading
      dataLoading: false,
      // 显示添加弹窗
      addNewVisible: false,
      // 证照类型
      licenseTypeList: [
        { id: 0, name: "全部" },
        { id: 1, name: "小证" },
        { id: 2, name: "大证" },
      ],
      // 编辑对象
      detail: {},
      // 编辑表格
      editModalForm: this.$form.createForm(this),
      // 编辑弹窗显示
      editVisible: false,
      // moadlVisible 显示模版弹出列表
      moadlVisible: false,
      // 模版列表ID
      templateID: "",
      // 日志列表
      templateData: [],
      templatePagination: defaultPagination(() => {}),
      //actioncolumns
      detailColumns: [
        {
          title: "模版编号",
          width: 160,
          dataIndex: "code",
          key: "code",
        },
        {
          title: "合同名称",
          width: 400,
          dataIndex: "fileName",
          key: "fileName",
        },
        {
          title: "模板上传时间",
          width: 160,
          dataIndex: "gmtCreated",
          key: "gmtCreated",
          scopedSlots: { customRender: "time" },
          width: "180px",
        },
        {
          title: "模版状态",
          width: 100,
          key: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: "130px",
        },
        {
          title: "操作内容",
          width: 160,
          dataIndex: "content",
          key: "content",
          scopedSlots: { customRender: "content" },
        },
      ],
      // listTemplateType
      templateTypeList: [],
      // 所有的城市（包括“全部”选项），与用户权限无关
      allCities: [],
      // 合同版本
      contractVersionType: [
        { code: 0, name: "全部" },
        { code: 1, name: "3.0版本" },
        { code: 2, name: "非3.0版本" },
      ],
    };
  },
  methods: {
    ...mapActions("cities", [GET_USER_CITIES]),
    moment,
    // 依据城市寻找品牌
    // 新建模版
    showAddModal() {
      this.addNewVisible = true;
    },
    // 新建提交
    addNewModal() {
      this.dataLoading = true;
      const {
        newModalForm: { validateFields },
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.submitNewTemplate(values);
        } else {
          this.dataLoading = false;
        }
      });
    },
    // submitBaseInfo
    submitNewTemplate(info) {
      axios({
        url:   this.$baseUrl + "contractTemplate/create",
        method: "POST",
        data: {
          ...info,
        },
      }).then((res) => {
        if (res.data.returnCode == "200") {
          this.$message.success(`${res.data.returnMsg}`);
          this.init();
          this.cancelAddNewModal();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.dataLoading = false;
      });
    },
    // 取消
    cancelAddNewModal() {
      this.addNewVisible = false;
    },
    // 编辑
    viewDetail(info) {
      this.detail = info;
      this.editVisible = true;
    },
    // cancelAddNewModal
    cancelEditModal() {
      this.detail = {};
      this.editVisible = false;
    },
    // changeEditModal 保存编辑
    changeEditModal() {
      this.dataLoading = true;
      const {
        editModalForm: { validateFields },
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.submitEditTemplate(values);
        } else {
          this.dataLoading = false;
        }
      });
    },
    submitEditTemplate(info) {
      axios({
        url:   this.$baseUrl + "contractTemplate/modify",
        method: "POST",
        data: {
          ...info,
          id: this.detail.id,
        },
      }).then((res) => {
        if (res.data.returnCode == "200") {
          this.$message.success(`${res.data.returnMsg}`);
          this.init();
          this.cancelEditModal();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.dataLoading = false;
      });
    },
    // handleViewModal 查看模版列表
    handleViewModal(info) {
      this.templateData = [];
      this.templateID = info.id || "";
      this.getTemplates();
    },
    // 获取模版列表
    getTemplates() {
      axios({
        url:   this.$baseUrl + "contractTemplate/listTemplateFile",
        method: "GET",
        params: {
          rows: 20,
          page: 1,
          contractTemplateId: this.templateID,
        },
      }).then((res) => {
        if (res.data.returnCode === "200" || res.data.returnCode === "201") {
          this.templateData = res.data.rows || [];
          this.templatePagination.total = res.data.count;
          this.dataLoading = false;
        } else {
          this.templateData = [];
          this.dataLoading = false;
        }
      });
      this.moadlVisible = true;
    },
    cancelVisible() {
      this.templateID = "";
      this.moadlVisible = false;
    },
    // 改变页
    changePageTemplateSize(val) {
      this.getTemplates(val.current, val.pageSize);
    },
    // 重置页面
    resetQueryParam() {
      this.queryParam = {};
      this.tableData = [];
      this.initTable();
    },
    // 分页
    changePageSize(val) {
      this.current = val.current;
      this.pageSize = val.pageSize;
      this.pagination.current = val.current;
      this.init();
    },
    initTable() {
      this.tableData = [];
      this.pageSize = 20;
      this.current = 1;
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.init();
    },
    // word上传
    uploadWord(file) {
      this.dataLoading = true;
      const formData = new FormData();
      formData.append("contractTemplateId", this.templateID);
      formData.append("multipartFile", file.file);

      axios({
        url: this.$baseUrl + "contractTemplate/createTemplateFile",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.success) {
            this.$message.info(`${res.data.returnMsg}`);
            this.getTemplates();
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
          this.moadlVisible = false;
          this.moadlVisible = true;
          this.dataLoading = false;
        })
        .catch((error) => {
          this.dataLoading = false;
          this.$message.error(error.message);
        });
    },
    // 获取合同类型
    getListTemplateType() {
      axios({
        url:   this.$baseUrl + "contractTemplate/listTemplateType",
        method: "GET",
      }).then((res) => {
        if (res.data.returnCode == "200") {
          this.templateTypeList = res.data.rows;
        }
      });
    },
    // 初始化
    init() {
      this.dataLoading = true;
      axios({
        url:   this.$baseUrl + "contractTemplate/list",
        method: "GET",
        params: {
          rows: this.pageSize || 20,
          page: this.current || 1,
          ...this.queryParam,
        },
      }).then((res) => {
        if (res.data.returnCode == "200") {
          this.tableData = res.data.rows || [];
          this.pagination.total = res.data.count;
          this.dataLoading = false;
        } else {
          this.pageSize = 20;
          this.current = 1;
          this.tableData = [];
          this.pagination.total = res.data.count;
          this.dataLoading = false;
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // 启用模版
    activeTemplate(info) {
      let contractTemplateFileId = info.id;
      axios({
        url:   this.$baseUrl + "contractTemplate/enableTemplateFile",
        method: "GET",
        params: {
          contractTemplateFileId: contractTemplateFileId,
        },
      }).then((res) => {
        if (res.data.returnCode == "200") {
          this.$message.info(`${res.data.returnMsg}`);
          this.getTemplates();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // downloadTemplate 下载模版
    downloadTemplate(info) {
      let contractTemplateFileId = info.id;
      axios({
        url:   this.$baseUrl + "contractTemplate/downloadTemplateFile",
        method: "GET",
        params: {
          contractTemplateFileId: contractTemplateFileId,
        },
      }).then((res) => {
        if (res.data.returnCode == "200") {
          window.open(res.data.obj, "_blank");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // 预览viewTemplate
    viewTemplate(info) {
      let contractTemplateFileId = info.id;
      axios({
        url:   this.$baseUrl + "contractTemplate/viewTemplateFile",
        method: "GET",
        params: {
          contractTemplateFileId: contractTemplateFileId,
        },
      }).then((res) => {
        if (res.data.returnCode == "200") {
          window.open(res.data.obj, "_blank");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
  },
  created() {
    // 初始化城市列表
    this.init();
    this.getUserCities();
    this.getListTemplateType();

    // 获取所有城市
    axios({
      url:   this.$baseUrl + "city/list",
      method: "GET",
      params: {},
    }).then((res) => {
      if (res.data.returnCode == "200") {
        this.allCities = [{ id: 0, name: "全部" }, ...res.data.rows];
      } else {
        this.$message.error(`${res.data.returnMsg}`);
      }
    });
  },
  computed: {
    ...mapGetters("cities", ["userCities"]),
    ...mapGetters("users", ["authenticatedUser"]),
  },
};
</script>
<style lang="less">
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ml15 {
  margin-left: 1.5em;
}
</style>
